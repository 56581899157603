//15 SIT
// let _config = {
//   jsonrpc: {
//     jsonrpc_url: "http://kthrp.karrytech.com:85/jsonrpc",
//     jsonrpc_dbname: "SIT",
//     jsonrpc_username: "kthrp_map",
//     jsonrpc_password: "1",
//   },
//   dd_config: {
//     corp_id: "dingd400cb9fd567ff3b35c2f4657eb6378f",
//   }
// };

//12 develop jiahui
let _config = {
  jsonrpc: {
    base_url: "https://hrp.arioncc.cn",
    jsonrpc_dbname: "Arion",
    jsonrpc_username: "kthrp_map",
    jsonrpc_password: "abcd123456",
  },
  dd_config: {
    corp_id: "ww50c382ee4c77bbec",
  }
};

//laowang
// let _config = {
//   jsonrpc: {
//     jsonrpc_url: "http://kthrp.karrytech.com:1051/jsonrpc",
//     jsonrpc_dbname: "dev111",
//     jsonrpc_username: "kthrp_map",
//     jsonrpc_password: "1",
//   },
//   dd_config: {
//     corp_id: "dingd400cb9fd567ff3b35c2f4657eb6378f",
//   }
// };



export default _config;
